.Stave {
  margin: 10px 40px;
}

@keyframes greenColorChange {
  0% {
    color: lightgreen;
  }
  33% {
    color: green;
  }
  66% {
    color: darkgreen;
  }
  100% {
    color: black;
  }
}

@keyframes redColorChange {
  0% {
    color: red;
  }
  100% {
    color: black;
  }
}

.correct-changing {
  animation: greenColorChange 1.66s;
}

.incorrect-changing {
  animation: redColorChange 1.66s;
}

.hide {
  display: none;
}

@keyframes loadingAnimation {
  0% {
    width: 1px;
  }
  100% {
    width: 250px;
  }
}

.loading {
  animation: loadingAnimation 1s;
}

.loader {
  background: 
  /* On "top" */ repeating-linear-gradient(
      45deg,
      transparent,
      transparent 10px,
      #ccc 10px,
      #ccc 20px
    ),
    /* on "bottom" */ linear-gradient(to bottom, #eee, #999);
  height: 50px;
  min-height: 100px;
  /* float: left; */
}
