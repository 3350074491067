.piano-intro {
  margin: 1em auto;
}

.piano {
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: min(calc(9px + 2vmin), 14pt);
}

#InstrumentSelect {
  padding: 12px 14px;
  background-color: #fff;
  border-radius: 4px;
  cursor: pointer;
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
    no-repeat 100% 50%;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  /* outline-width: 0; */
}

td {
  border-radius: 3px;
}

@keyframes greenColorChange {
  0% {
    color: lightgreen;
  }
  33% {
    color: green;
  }
  66% {
    color: darkgreen;
  }
  100% {
    color: black;
  }
}

@keyframes orangeColorChange {
  0% {
    color: orange;
  }
  100% {
    color: black;
  }
}

@keyframes blueColorChange {
  0% {
    color: blue;
  }
  100% {
    color: black;
  }
}

@keyframes purpleColorChange {
  0% {
    color: purple;
  }
  100% {
    color: black;
  }
}

@keyframes pinkColorChange {
  0% {
    color: pink;
  }
  100% {
    color: black;
  }
}

@keyframes aquamarineColorChange {
  0% {
    color: aquamarine;
  }
  100% {
    color: black;
  }
}

@keyframes aquaColorChange {
  0% {
    color: aqua;
  }
  100% {
    color: black;
  }
}

.green-changing {
  animation: greenColorChange 1.66s;
}

.red-changing {
  animation: redColorChange 1.66s;
}

.pink-changing {
  animation: pinkColorChange 1.66s;
}

.orange-changing {
  animation: orangeColorChange 1.66s;
}

.blue-changing {
  animation: blueColorChange 1.66s;
}

.purple-changing {
  animation: purpleColorChange 1.66s;
}

.aquamarine-changing {
  animation: aquamarineColorChange 1.66s;
}

.aqua-changing {
  animation: aquaColorChange 1.66s;
}

.hide {
  display: none;
}
